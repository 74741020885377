import { useHistory } from "react-router-dom";
import { closeSvg } from '@icons';

import "./closeButton.scss";

const CloseButton = ({ path }) => {
    const history = useHistory();

    const onClick = () => {
        history.push(path);
    }

    return <img src={closeSvg} className="close-button" onClick={onClick} />
}

export default CloseButton;