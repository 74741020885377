import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'succeeded' | 'failed'
 *  error: string | null
 */
export const initialState = {
    status: "idle",
    choosedTest: {},
    error: null
};

export const chooseSlice = createSlice({
    name: "choose",
    initialState,
    reducers: {
        chooseFetch: state => {
            state.status = "loading";
        },
        chooseFetchSuccess: (state, { payload }) => {
            state.status = "success";
            state.choosedTest = { ...payload }
        },
        chooseFetchFail: (state, { payload }) => {
            state.status = "fail";
            state.choosedTest = {};
            state.error = payload;
        }
    }
});

export const { chooseFetch, chooseFetchSuccess, chooseFetchFail, setNeedRedirectFlag, resetNeedRedirectFlag } = chooseSlice.actions;
export default chooseSlice.reducer;