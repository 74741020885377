import { DesktopOrTabletBreakpoint, MobileBreakpoint } from '@components';
import TimelineInfoDesktop from './timelineInfoDesktop/TimelineInfoDesktop';
import TimelineInfoMobile from './timelineInfoMobile/TimelineInfoMobile';

const TimelineInfo = ({ ...props }) => {
    return (<>
        <DesktopOrTabletBreakpoint>
            <TimelineInfoDesktop {...props} />
        </DesktopOrTabletBreakpoint>

        <MobileBreakpoint>
            <TimelineInfoMobile {...props} />
        </MobileBreakpoint >
    </>
    )
}

export default TimelineInfo;