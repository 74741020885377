import { Typography } from 'antd';

import "./title.scss";

const { Title: AntTitle } = Typography;

const Title = ({ title, level = 5, ...props }) => (
    <AntTitle
        className="lyfe-title"
        key={title}
        level={level}
        {...props}>
        {title}
    </AntTitle>
);

export default Title;
