import { call, put, takeLatest } from 'redux-saga/effects';
import { chooseFetch, chooseFetchSuccess, chooseFetchFail } from '@slice/chooseSlice';
import { choosedTestInfo } from '@utils/lyfe-api';

function* chooseSaga({ payload }) {
    try {
        const response = yield call(choosedTestInfo, payload);
        const { success, data } = response;

        if (success) {
            yield put(chooseFetchSuccess(data));
        } else {
            yield put(chooseFetchFail());
        }
    } catch (exception) {
        yield put(chooseFetchFail());
    }
}

export default function* () {
    yield takeLatest(chooseFetch, chooseSaga);
}