import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    needRedirect: false,
    pathTo: ""
};

export const redirectSlice = createSlice({
    name: "redirect",
    initialState,
    reducers: {
        setRedirectInfo: (state, { payload }) => {
            state.needRedirect = payload.needRedirect;
            state.pathTo = payload.pathTo;
        },
        resetRedirectInfo: state => {
            state.needRedirect = false;
            state.pathTo = "";
        }
    }
});

export const { setRedirectInfo, resetRedirectInfo } = redirectSlice.actions;
export default redirectSlice.reducer;