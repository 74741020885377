import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { timelineFetch } from "@slice/timelineSlice";
import { setRedirectInfo, resetRedirectInfo } from "@slice/redirectSlice";
import { Space } from 'antd';
import { Title, LFTBlock, BackButton, Button, DesktopOrTabletBreakpoint, MobileBreakpoint, FixedButton } from '@components';
import lft_img1 from '@assets/img/lft/lft_img1.svg';
import lft_kit_img1 from '@assets/img/lft/lft_kit_img1.svg';
import infoGraph from '@assets/img/lft/info-graph.png';
import infoGraphMobile from '@assets/img/lft/info-graph-mobile.png';

import './lftPage.scss';

const LFTPage = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const history = useHistory();

    const filterParams = useSelector(state => state.searchSlice.filterParams);
    const choosedTest = useSelector(state => state.chooseSlice.choosedTest);
    const timelineFetching = useSelector(state => state.timelineSlice.status);
    const redirectInfo = useSelector(state => state.redirectSlice);

    // redirect to 'choose' page, if data not loaded
    useEffect(() => {
        if (Object.keys(choosedTest).length === 0) history.push('/choose');
    }, [choosedTest]);

    // Upd [redirectInfo, timelineFetching]; redirect when data is load
    useEffect(() => {
        if (redirectInfo.needRedirect && timelineFetching === "success") history.push(redirectInfo.pathTo);
    }, [redirectInfo, timelineFetching]);


    // Unmount
    useEffect(() => {
        return () => props.resetRedirectInfo();
    }, []);

    const handleContinue = () => {
        const { setRedirectInfo, timelineFetch } = props;
        const { choosedTestId, date: deadline } = filterParams;

        setRedirectInfo({ needRedirect: true, pathTo: "/hiw" }); // when data will be load, redirect to 'how it works' page
        timelineFetch({ choosedTestId, deadline });
    };

    const handleClose = () => {
        history.push("/choose");
    }

    return (
        <div className="lft-wrapper">
            <DesktopOrTabletBreakpoint>
                <BackButton path='/choose' />
            </DesktopOrTabletBreakpoint>

            <div className="flow-info">
                <div className="flow-head">
                    <Title level={1} title="Lateral Flow Test" />

                    <DesktopOrTabletBreakpoint>
                        <Button type="primary" title="Continue" style={{ width: '160px', height: '48px' }} onClick={handleContinue} loading={timelineFetching === 'loading'} />
                    </DesktopOrTabletBreakpoint>
                </div>

                <Space size={isMobile ? 32 : 44} direction="vertical">
                    <LFTBlock title="What is a lateral flow immunoassay?" imgSrc={lft_img1} textPos="second">{choosedTest.description}</LFTBlock>
                    <LFTBlock title="How does a lateral flow test work?" videoSrc={choosedTest.video_url}>{choosedTest.description}</LFTBlock>
                </Space>
            </div>

            <div className="kit-info">
                <Title level={1} title="Testing Kit Instructions" />

                <div className="lft-subtitle">Self-Collected, Oral Fluid Swab</div>

                <Space size={isMobile ? 32 : 44} direction="vertical">
                    <LFTBlock title="How to do self testing?" imgSrc={lft_kit_img1} textPos="second">{choosedTest.short_description}</LFTBlock>
                    <LFTBlock title="How does a lateral flow test work?" videoSrc={choosedTest.conduct_video_url}>{choosedTest.conduct_description}</LFTBlock>
                </Space>
            </div>
            <div className="info-graph"><img src={isMobile ? infoGraphMobile : infoGraph} /></div>

            <MobileBreakpoint>
                <FixedButton>
                    <Button type="primary" htmlType="submit" title="continue" onClick={handleContinue} loading={timelineFetching === 'loading'} />
                    <Button title="close" style={{ marginTop: '24px' }} onClick={handleClose} />
                </FixedButton>
            </MobileBreakpoint>
        </div >
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        timelineFetch,
        setRedirectInfo,
        resetRedirectInfo
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(LFTPage);
