import { Layout } from 'antd';
import { signUpSvg } from '@icons';
import { DesktopOrTabletBreakpoint, MobileBreakpoint } from "@components";

import "./header.scss";

const { Header: AntHeader } = Layout;

const Header = () => (
    <AntHeader className="header-wrapper">

        <DesktopOrTabletBreakpoint>
            <div className="logo">Lyfe</div>
            <div className="profile">
                <div className="sign-up">
                    <span>Sign Up</span>
                    <img className="avatar" src={signUpSvg} />
                </div>
            </div>
        </DesktopOrTabletBreakpoint>

        <MobileBreakpoint>
            <div className="menu">menu</div>
            <div className="logo">Lyfe</div>
            <div className="profile">
                <img className="avatar" height={32} width={32} src={signUpSvg} />
            </div>
        </MobileBreakpoint>

    </AntHeader>
);

export default Header;
