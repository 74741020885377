import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'succeeded' | 'failed'
 *  error: string | null
 */
export const initialState = {
    status: "idle",
    value: null,
    error: null
};

export const testSlice = createSlice({
    name: "test",
    initialState,
    reducers: {
        testFetch: state => {
            state.status = "loading";
        },
        testFetchSuccess: (state, { payload }) => {
            state.status = "success";
            state.value = payload;
        },
        testFetchFail: state => {
            state.initialState = "fail";
            state.value = null;
        }
    }
});

export const { testFetch, testFetchSuccess, testFetchFail } = testSlice.actions;
export default testSlice.reducer;