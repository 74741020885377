import { Title } from '@components';

import './orderSummary.scss';

const OrderSummary = ({ cost = 39, quantity = 1, summary, ...props }) => {
    return <div className="order-summary-wrap" {...props}>
        <Title level={4} title="Order Summary" />

        <div className="summary-content-wrap">
            <div className="content-cost-info content-item">
                <span>{quantity} Item{quantity > 1 && 's'}</span>
                <span>£{cost}</span>
            </div>
            <div className="content-delivery-status content-item">
                <span>Delivery</span>
                <span>Free</span>
            </div>
            <div className="content-summary content-item">Total: <span className="summary-value">£{cost * quantity}</span></div>
        </div>
    </div>
}

export default OrderSummary;