import moment from 'moment';

/**
 * allFields - fields from antd form
 * create obj for /tests/search requests.
*/
export const createTestsSearchParams = allFields => {
    const filterParams = {};

    for (const prop in allFields) {
        if (allFields[prop] === null || allFields[prop] === undefined) {
            delete allFields[prop];
            break;
        }

        if (prop === "typeFlight") {
            filterParams.is_arriving = allFields[prop] === "arrive";
        } else {
            filterParams[prop] = allFields[prop];
        }
    }

    if (filterParams.date) {
        const momentDate = moment(filterParams.date);

        let time = { hour: 0, minute: 0 };
        if (filterParams.time) {
            const momentTime = moment(filterParams.time, 'hh:mm A');

            time.hour = momentTime.hours();
            time.minute = momentTime.minutes();
        }

        momentDate.set({ ...time, second: 0, millisecond: 0 });

        filterParams.deadline = momentDate.format("YYYY-MM-DDTHH:mm:ss.ssZ");

        delete filterParams.date;
        delete filterParams.time;
    } else {
        delete filterParams.time;
    }

    return filterParams;
}

// parse date and time from 'Choose test' page from moment object to str
export const parseFilterParamsToStr = filterParams => {
    const parsedParams = { ...filterParams };

    if (parsedParams.date) {
        parsedParams.date = parsedParams.date.format();
    }

    if (parsedParams.time) {
        parsedParams.time = parsedParams.time.format('hh:mm A');
    }

    return parsedParams;
}

// parse date and time from 'Choose test' page from str to moment object
export const parseFilterParamsToMomemnt = filterParams => {
    const parsedParams = { ...filterParams };

    if (parsedParams.date) {
        parsedParams.date = moment(parsedParams.date);
    }

    if (parsedParams.time) {
        parsedParams.time = moment(parsedParams.time, 'hh:mm A')
    }

    return parsedParams;
}


