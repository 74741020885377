import { useHistory } from "react-router-dom";
import { backSvg } from '@icons';

import "./backButton.scss";

const BackButton = ({ path }) => {
    const history = useHistory();

    const onClick = () => {
        history.push(path);
    }

    return <img src={backSvg} className="back-button" onClick={onClick} />
}

export default BackButton;