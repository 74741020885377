import { Button as AntButton } from 'antd';

import "./button.scss";

const Button = ({ title, ...props }) => {
    return (
        <AntButton
            {...props}
            className="button-wrapper">
            {title}
        </AntButton >
    )
};

export default Button;
