/**
 * from request /maps/autocomplete create options for autocomplete component
 * {name, google_place_id}[] => {name, google_place_id, label, value}[]
 */
export const modifyAutocompleteOptions = (options = []) => {
    return options.map(option => {
        return { ...option, label: option.name, value: option.name }
    });
}

// find address into addresses[] by value and return google_place_id
export const getGooglePlaceId = (addresses = [], value = "") => {
    const foundedAddress = addresses.filter(address => address.value === value);

    if(foundedAddress.length === 0) return undefined;
    
    return foundedAddress[0]['google_place_id']
};