import MediaQuery from "react-responsive";

const breakpoints = {
    desktop: "(min-width: 1040px)",
    desktopOrTablet: "(min-width: 768px)",
    tablet: "(min-width: 768px) and (max-width: 1039px)",
    mobile: "(max-width: 767px)",
};

const Breakpoint = props => {
    const breakpoint = breakpoints[props.name] || breakpoints.desktop;

    return (
        <MediaQuery {...props} query={breakpoint} >
            { props.children}
        </MediaQuery >
    )
}

export const DesktopBreakpoint = props => (
    <Breakpoint name="desktop">
        {props.children}
    </Breakpoint>
);

export const DesktopOrTabletBreakpoint = props => (
    <Breakpoint name="desktopOrTablet">
        {props.children}
    </Breakpoint>
);


export const MobileBreakpoint = props => (
    <Breakpoint name="mobile">
        {props.children}
    </Breakpoint>
);


export const TabletBreakpoint = props => (
    <Breakpoint name="tablet">
        {props.children}
    </Breakpoint>
);

export default Breakpoint;
