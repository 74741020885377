import { Title } from '@components';
import moment from 'moment';

import { timelineConfig } from './../timelineConfig';

import "./timelineInfoMobile.scss";

const TimelineInfoMobile = ({ events }) => {
    const content = events.map((event, i) => {
        const { category, name, description, threshold } = event;

        return <div key={`step-${i}`} className="timeline-step-wrapper">
            <div className="timeline-step-number">{i + 1}</div>
            {i + 1 < events.length && <div className="timeline-steps-line"></div>}
            <div className="timeline-step-content-wrapper">
                <div className="timeline-content-header">
                    <img src={timelineConfig[category]} />
                    <div className="timeline-title-wrapper">
                        <div className="timeline-step-title-date">{moment(threshold).format("DD MMMM, h:mm A")}</div>
                        <div className="timeline-step-title">{name}</div>
                    </div>
                </div>
                <div className="timeline-description">{description} description description description description descriptiondescriptiondescriptiondescription description description</div>
            </div>

        </div>
    });

    return (<div className="timeline-info-mobile-wrapper">{content}</div>)
}

export default TimelineInfoMobile;