import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import { calendarSvg } from "@icons";

import "./datePicker.scss";

const visibleDateFormat = "MMMM, DD";

const DatePicker = ({ width, onChange, value, ...props }) => {
    if (typeof value === "string") value = moment(value);

    return (
        <AntDatePicker
            style={width && { width: `${width}px` }}
            format={visibleDateFormat}
            suffixIcon={<img src={calendarSvg} />}
            {...props}
            onChange={val => onChange(val)}
            value={value}
            disabledDate={disabledDate}
            className="date-picker-wrapper" />
    )
};

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}

export default DatePicker;
