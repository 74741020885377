import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'succeeded' | 'failed'
 *  error: string | null
 */
export const initialState = {
    status: "idle",
    events: [],
    threshold: null,
    error: null
};

export const timelineSlice = createSlice({
    name: "timeline",
    initialState,
    reducers: {
        timelineFetch: state => {
            state.status = "loading";
        },
        timelineFetchSuccess: (state, { payload }) => {
            state.status = "success";
            state.threshold = payload.threshold;
            state.events = [...payload.events];
        },
        timelineFetchFail: (state, { payload }) => {
            state.status = "fail";
            state.threshold = {};
            state.events = [];
            state.error = payload;
        }
    }
});

export const { timelineFetch, timelineFetchSuccess, timelineFetchFail } = timelineSlice.actions;
export default timelineSlice.reducer;
