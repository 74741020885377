import testSlice from "./testSlice";
import searchSlice from "./searchSlice";
import chooseSlice from "./chooseSlice";
import redirectSlice from "./redirectSlice";
import timelineSlice from "./timelineSlice";
import addressSlice from "./addressSlice";

export const reducers = {
    testSlice,
    searchSlice,
    chooseSlice,
    redirectSlice,
    timelineSlice,
    addressSlice
};