import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'succeeded' | 'failed'
 *  error: string | null
 */
export const initialState = {
    status: "idle",
    addressOptions: [],
    addressInput: null,
    addressDetailStatus: "idle",
    addressDetail: {},
    error: null
};

// for autocomplete address field
export const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        addressFetch: state => {
            state.status = "loading";
        },
        addressFetchSuccess: (state, { payload }) => {
            state.status = "success";
            state.addressOptions = [...payload.options]
            state.addressInput = payload.input
        },
        addressFetchFail: (state, { payload }) => {
            state.status = "fail";
            state.addressOptions = [];
            state.addressInput = null;
            state.error = payload;
        },
        addressDetailFetch: state => {
            state.addressDetailStatus = "loading";
        },
        addressDetailFetchSuccess: (state, { payload }) => {
            state.addressDetailStatus = "success";
            state.addressDetail = {...payload}
        },
        addressDetailFetchFail: state => {
            state.addressDetailStatus = "fail";
            state.addressDetail = {};
        }
    }
});

export const { addressFetch, addressFetchSuccess, addressFetchFail, addressDetailFetch, addressDetailFetchSuccess, addressDetailFetchFail } = addressSlice.actions;
export default addressSlice.reducer;