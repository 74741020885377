import ReactPlayer from 'react-player';
import CustomScroll from 'react-custom-scroll';
import { DesktopOrTabletBreakpoint, MobileBreakpoint } from '@components';

import './lftBlock.scss';

/**
 * textPos First: text - img/video
 * textPos Second: img/video - text
 * in Mobile First: text upper img/video. Second: img/video upper text
 */

const LFTBlock = ({ title, imgSrc, videoSrc, textPos, children }) => {
    return (
        <div className={`lft-block-wrapper${textPos === "second" ? " second" : " first"}`} >
            <div className="text-content">
                <div className="content-title">{title}</div>

                <DesktopOrTabletBreakpoint>
                    <CustomScroll allowOuterScroll={true}>
                        <div className="content-description">{children}
                        LFDs use immunoassay technology using nitrocellulose membrane, coloured nanoparticles (or labels), and typically antibodies, to produce results.
           </div>
                    </CustomScroll>
                </DesktopOrTabletBreakpoint>

                <MobileBreakpoint>
                    <div className="content-description">{children}
                        LFDs use immunoassay technology using nitrocellulose membrane, coloured nanoparticles (or labels), and typically antibodies, to produce results.
                        When a sample is added, the sample will flow along the test device passing through the conjugate pad into the nitrocellulose membrane and then onto the absorbent pad.
                        The bullet points below demonstrate how a sandwich assay works. Alternatively, click the play button on this image to watch a video. click the play button on this image to watch a video.
                        When a sample is added, the sample will flow along the test device passing through the conjugate pad into the nitrocellulose membrane and then onto the absorbent pad.
                        The bullet points below demonstrate how a sandwich assay works. Alternatively, click the play button on this image to watch a video. click the play button on this image to watch a video.
                        When a sample is added, the sample will flow along the test device passing through the conjugate pad into the nitrocellulose membrane and then onto the absorbent pad.
                        The bullet points below demonstrate how a sandwich assay works. Alternatively, click the play button on this image to watch a video. click the play button on this image to watch a video.
                        When a sample is added, the sample will flow along the test device passing through the conjugate pad into the nitrocellulose membrane and then onto the absorbent pad.
                        The bullet points below demonstrate how a sandwich assay works. Alternatively, click the play button on this image to watch a video. click the play button on this image to watch a video.

                    </div>
                </MobileBreakpoint>
            </div>

            <div className="media-content">
                {imgSrc && <img src={imgSrc} />}
                {videoSrc && <ReactPlayer url={videoSrc} height={226} width="100%" controls={true}/>}
            </div>
        </div>
    )
}

export default LFTBlock;