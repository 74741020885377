import { TimePicker as AntTimePicker } from 'antd';
import { timeSvg } from "@icons";
import moment from 'moment';

import "./timePicker.scss";

const visibleTimeFormat = "h:mm A";

const TimePicker = ({ width, onChange, value, ...props }) => {
    return (
        <AntTimePicker
            style={width && { width: `${width}px` }}
            format={visibleTimeFormat}
            use12Hours
            suffixIcon={<img src={timeSvg} />}
            onChange={val => onChange(val)}
            value={value}
            {...props}
            className="time-picker-wrapper" />
    )
};

export default TimePicker;
