import { Radio } from 'antd';

import "./radioButton.scss";

const RadioButton = ({ title, icon, value, ...props }) => {
    return (
        <Radio.Button
            value={value}
            {...props}
            className="radio-button-wrapper" >
            {icon && <img src={icon} />}
            <span>{title}</span>
        </Radio.Button >
    )
};

export default RadioButton;
