import Router from "@router";

import './app.scss';
import '@styles/react-custom-scroll.css';

const App = () => {
  return (
    <div className="App">
      <Router auth={false}/>
    </div>
  );
}

export default App;
