import { useMemo, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import CustomScroll from 'react-custom-scroll';
import { useMediaQuery } from 'react-responsive';
import { searchFetch, modifyFilter } from "@slice/searchSlice";
import { chooseFetch } from "@slice/chooseSlice";
import { setRedirectInfo, resetRedirectInfo } from "@slice/redirectSlice";
import { Form, Radio, Space, Spin, Tooltip } from 'antd';
import { createTestsSearchParams, parseFilterParamsToStr, parseFilterParamsToMomemnt } from '@utils/parser';
import { RadioButton, Button, DatePicker, TimePicker, Title, TestRadioCard, DesktopOrTabletBreakpoint, MobileBreakpoint } from '@components';
import { aidSvg, arriveSvg, departSvg, travellingSvg } from '@icons';

import "./choosePage.scss";

const ChoosePage = (props) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [form] = Form.useForm();
    const history = useHistory();

    const [purpose, setPurpose] = useState(null);
    const [typeFlight, setTypeFlight] = useState(null);
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [choosedTestId, setChoosedTestId] = useState(null);
    const [isDisableChoose, setDisableChoose] = useState(true);

    const searchFetching = useSelector(state => state.searchSlice.status);
    const tests = useSelector(state => state.searchSlice.availableTests);
    const chooseParams = useSelector(state => state.searchSlice.filterParams);
    const chooseFetching = useSelector(state => state.chooseSlice.status);
    const redirectInfo = useSelector(state => state.redirectSlice);

    let availableTests = useMemo(() => {
        if (tests.length > 0) {
            return tests.map(test => <TestRadioCard key={test.id} isChecked={test.id === choosedTestId} {...test} isDisableChoose={isDisableChoose} />);
        } else {
            return purpose ? <TestRadioCard isEmpty={true} /> : <div className="tutorial-msg">Start selecting purpose to view suitable tests product.</div>;
        }
    }, [tests, choosedTestId, isDisableChoose]);

    // DidMount; set state and fields value from props;
    useEffect(() => {
        const filterParams = parseFilterParamsToMomemnt(chooseParams);
        const { purpose, typeFlight, date, time, choosedTestId } = filterParams;

        purpose && setPurpose(purpose);
        typeFlight && setTypeFlight(typeFlight);
        date && setDate(date);
        time && setTime(time);
        choosedTestId && setTimeout(() => setChoosedTestId(choosedTestId), 0); // hack, call setChoosedTestId after all useEffects.

        form.setFieldsValue({
            ...filterParams
        });
    }, []);

    // Upd [redirectInfo, chooseFetching]; redirect when data is load
    useEffect(() => {
        if (redirectInfo.needRedirect && chooseFetching === "success") history.push(redirectInfo.pathTo);
    }, [redirectInfo, chooseFetching]);

    // Upd [purpose, typeFlight, date, time]; re-search available tests
    useEffect(() => {
        if (purpose === null) return;

        setChoosedTestId(null);
        form.resetFields(["chooseTest"]);

        setDisableChoose(date ? false : true);

        props.searchFetch(createTestsSearchParams({ purpose, typeFlight, date, time }));
    }, [purpose, typeFlight, date, time]);

    // Unmount
    useEffect(() => {
        return () => props.resetRedirectInfo();
    }, []);

    const onFinish = values => {
        const { setRedirectInfo, chooseFetch, modifyFilter } = props;

        console.log(values);
        console.log(`purpose: ${purpose}; typeFlight=${typeFlight}; date=${date}; time=${time}, chooseId: ${choosedTestId}`);
        modifyFilter({ ...parseFilterParamsToStr(values), choosedTestId });

        setRedirectInfo({ needRedirect: true, pathTo: "/lft" }); // when data will be load, redirect to 'lateral flow test' page
        chooseFetch(choosedTestId);
    };

    return (
        <Form
            form={form}
            name="choose-form"
            onFinish={onFinish}
            layout="vertical">
            <div className="choose-col-one">
                <div className="title">Choose test</div>

                <DesktopOrTabletBreakpoint>
                    <Form.Item
                        label={<Title level={4} title="Select purpose" />}
                        name="purpose"
                        className="purpose-form-item"
                    >
                        <Radio.Group onChange={e => setPurpose(e.target.value)} value={purpose}>
                            <Space size={12}>
                                <RadioButton value="travelling" title="Travelling" icon={travellingSvg} style={{ width: '200px' }} />
                                <RadioButton value="general" title="Peace of mind" icon={aidSvg} style={{ width: '200px' }} />
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </DesktopOrTabletBreakpoint>

                <MobileBreakpoint>
                    <Form.Item
                        label={<Title level={4} title="Select purpose" />}
                        name="purpose"
                        className="purpose-form-item"
                    >
                        <Radio.Group onChange={e => setPurpose(e.target.value)} value={purpose} style={{ width: '100%' }}>
                            <Space size={12} direction='vertical' style={{ width: '100%' }}>
                                <RadioButton value="travelling" title="Travelling" icon={travellingSvg} />
                                <RadioButton value="general" title="Peace of mind" icon={aidSvg} />
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </MobileBreakpoint>

                {purpose === "travelling" && <>
                    <DesktopOrTabletBreakpoint>
                        <Form.Item
                            label={<Title level={4} title="You departing or arriving in the UK?" />}
                            name="typeFlight"
                            style={{ marginTop: '52px' }}
                        >
                            <Radio.Group onChange={e => setTypeFlight(e.target.value)} value={typeFlight}>
                                <Space size={12}>
                                    <RadioButton value="arrive" title="when do you arrive" icon={arriveSvg} style={{ width: '240px' }} />
                                    <RadioButton value="depart" title="when do you depart" icon={departSvg} style={{ width: '240px' }} />
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </DesktopOrTabletBreakpoint>

                    <MobileBreakpoint>
                        <Form.Item
                            label={<Title level={4} title="You departing or arriving in the UK?" />}
                            name="typeFlight"
                            style={{ marginTop: '32px' }}
                        >
                            <Radio.Group onChange={e => setTypeFlight(e.target.value)} value={typeFlight} style={{ width: '100%' }}>
                                <Space size={12} direction='vertical' style={{ width: '100%' }}>
                                    <RadioButton value="arrive" title="when do you arrive" icon={arriveSvg} />
                                    <RadioButton value="depart" title="when do you depart" icon={departSvg} />
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </MobileBreakpoint>
                </>}

                {(typeFlight || purpose === "general") &&
                    <div style={{ marginTop: isMobile ? '32px' : '52px' }}>
                        <DesktopOrTabletBreakpoint>
                            <Title level={4} title="When do you need test results by?" />
                            <div className="date-time-wrapper">
                                <Form.Item label={<Title level={5} title="Date" />} name="date">
                                    <DatePicker onChange={setDate} value={date} />
                                </Form.Item>

                                <div className="date-time-divider"></div>

                                <Form.Item label={<Title level={5} title="Time" />} name="time">
                                    <TimePicker onChange={setTime} value={time} />
                                </Form.Item>
                            </div>
                        </DesktopOrTabletBreakpoint>

                        <MobileBreakpoint>
                            <Title level={4} title="When do you need test results by?" />
                            <div className="date-time-wrapper">
                                <Form.Item label={<Title level={5} title="Date" />} name="date" style={{ flex: '1', marginLeft: '2px', minWidth: '175px' }}>
                                    <DatePicker onChange={setDate} value={date} />
                                </Form.Item>

                                <div className="date-time-divider"></div>

                                <Form.Item label={<Title level={5} title="Time" />} name="time" style={{ flex: '1', marginRight: '2px' }}>
                                    <TimePicker onChange={setTime} value={time} />
                                </Form.Item>
                            </div>
                        </MobileBreakpoint>
                    </div>
                }

                <DesktopOrTabletBreakpoint>
                    <Button type="primary" htmlType="submit" title="continue" style={{ marginTop: '56px' }} disabled={choosedTestId === null} loading={chooseFetching === 'loading'} />
                </DesktopOrTabletBreakpoint>

            </div>

            <div className="choose-col-two">
                <div className="title">Suitable tests</div>
                {searchFetching === "loading"
                    ? <Spin size="large" />
                    : <Form.Item name="chooseTest" className="choose-test-wrapper">
                        <CustomScroll allowOuterScroll={true}>
                            <Radio.Group onChange={e => setChoosedTestId(e.target.value)} value={choosedTestId} className="choose-test-content" style={{ width: isMobile ? '100%' : 'auto' }}>
                                <Space size={20} direction="vertical" style={{ width: isMobile ? '100%' : 'auto' }}>
                                    {availableTests}
                                </Space>
                            </Radio.Group>
                        </CustomScroll>
                    </Form.Item>
                }
            </div>

            <MobileBreakpoint>
                <div className="mobile-button-wrap">
                    <Button type="primary" htmlType="submit" title="continue" style={{ marginTop: '48px' }} disabled={choosedTestId === null} loading={chooseFetching === 'loading'} />
                    <Button title="close" style={{ marginTop: '24px' }} />
                </div>
            </MobileBreakpoint>
        </Form>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        searchFetch,
        chooseFetch,
        setRedirectInfo,
        resetRedirectInfo,
        modifyFilter
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(ChoosePage);
