import { Layout } from 'antd';
import { Header, Footer, DesktopOrTabletBreakpoint } from "@components";

import "./mainLayout.scss";

const { Content } = Layout;

const MainLayout = ({children, contentHeight}) => (
    <Layout className="main-layout-wrapper" style={{ minHeight: '100vh' }}>
        <div className="main-layout-content">
            <Header />

            <Content className="content-wrapper">
                <div className="content" style={{minHeight: contentHeight ? contentHeight : '736px'}}>
                    {children}
                </div>
            </Content>

            <DesktopOrTabletBreakpoint>
                <Footer />
            </DesktopOrTabletBreakpoint>
        </div>
    </Layout >
);

export default MainLayout;