import { Link } from "react-router-dom";
import { Layout } from 'antd';
import { instaSvg, facebookSvg, twitterSvg } from '@icons';

import "./footer.scss";

const { Footer: AntFooter } = Layout;

const Footer = () => (
    <AntFooter className="footer-wrapper">
        <div className="copyright">© 2021 Lyfe</div>

        <Link to="/about" className="link-item">About</Link>
        <Link to="/help" className="link-item">Help</Link>
        <Link to="/contact-us" className="link-item">Contact us</Link>
        <Link to="/terms-and-conditions" className="link-item">Terms and conditions</Link>

        <div className="social-icons">
            <Link to="/insta"><img src={instaSvg} /></Link>
            <Link to="/facebook"><img src={facebookSvg} /></Link>
            <Link to="/twitter"><img src={twitterSvg} /></Link>
        </div>
    </AntFooter>
);

export default Footer;
