import { createSlice } from '@reduxjs/toolkit';

/**
 *  status: 'idle' | 'loading' | 'succeeded' | 'failed'
 *  error: string | null
 */
export const initialState = {
    status: "idle",
    filterParams: {},
    availableTests: [],
    choosedTestId: null,
    error: null
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        searchFetch: state => {
            state.status = "loading";
        },
        searchFetchSuccess: (state, { payload }) => {
            state.status = "success";
            state.availableTests = [...payload];
        },
        searchFetchFail: (state, { payload }) => {
            state.status = "fail";
            state.filterParams = {};
            state.availableTests = [];
            state.choosedTestId = null;
            state.error = payload;
        },
        modifyFilter: (state, { payload }) => {
            state.filterParams = { ...payload };
            state.choosedTestId = payload.choosedTestId;
        }
    }
});

export const { searchFetch, searchFetchSuccess, searchFetchFail, modifyFilter } = searchSlice.actions;
export default searchSlice.reducer;