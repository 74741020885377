let config;

switch (process.env.REACT_APP_MODE) {
    case "staging":
        config = {
            env: "staging",
            lyfeApiUrl: "https://api.lyfe.dev.magora.uk"
        };
        break;
    case "demo":
        config = {
            env: "demo",
            lyfeApiUrl: "https://api.lyfe-demo.dev.magora.uk"
        };
        break;
    default:
        config = {
            env: "dev",
            lyfeApiUrl: "https://api.lyfe.dev.magora.uk"
        };
        break;
}

export default config;