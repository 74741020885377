import { useEffect, useState } from 'react';
import { Title } from '@components';
import moment from 'moment';

import { timelineConfig } from './../timelineConfig';

import "./timelineInfoDesktop.scss";

const TimelineInfo = ({ events = [] }) => {
    const [times, setTimes] = useState(null);
    const [circles, seCircles] = useState(null);
    const [descs, setDescs] = useState(null);

    useEffect(() => {
        const timesCol = [];
        const descsCol = [];
        const circlesCol = [];

        events.forEach((event, i) => {
            const { category, name, description, threshold } = event;

            timesCol.push(<div key={`time-${category}`} className="timeline-time-item timeline-row">{moment(threshold).format("DD MMMM, h:mm A")}</div>);

            circlesCol.push(<div key={`circle-${category}`} className="timeline-step-wrapper timeline-row">
                <div className="timeline-circle">{i + 1}</div>
                {i + 1 < events.length && <div className="timeline-line"></div>}
            </div>);

            descsCol.push(<div key={`desc-${category}`} className="timeline-descs-wrapper timeline-row">
                <img src={timelineConfig[category]} />
                <div>
                    <div className="timeline-step-title">{name}</div>
                    <div className="timeline-description">{description} description description description description descriptiondescriptionde scriptiondescription description description</div>
                </div>
            </div>);
        });

        setTimes(timesCol);
        seCircles(circlesCol);
        setDescs(descsCol);
    }, [events]);

    return (
        <div className="timeline-info-wrapper">
            <div className="timeline-info-time-col">{times}</div>
            <div className="timeline-info-step-col">{circles}</div>
            <div className="timeline-info-descs-col">{descs}</div>
        </div>
    )
};

export default TimelineInfo;