import { call, put, takeLatest } from 'redux-saga/effects';
import { timelineFetch, timelineFetchSuccess, timelineFetchFail } from '@slice/timelineSlice';
import { getTimeline } from '@utils/lyfe-api';

function* timelineSaga({ payload }) {
    try {
        const response = yield call(getTimeline, payload);
        const { success, data } = response;

        if (success) {
            yield put(timelineFetchSuccess(data));
        } else {
            yield put(timelineFetchFail());
        }
    } catch (exception) {
        yield put(timelineFetchFail());
    }
}

export default function* () {
    yield takeLatest(timelineFetch, timelineSaga);
}