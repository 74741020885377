import { Bugfender } from '@bugfender/sdk';
import config from "@config";

Bugfender.init({
    appKey: 'iOghEvkFrisDZyZhUbJiuj0lg8x3XGx0',
    // apiURL: 'https://api.bugfender.com',
    // baseURL: 'https://dashboard.bugfender.com',
     overrideConsoleMethods: true,
    // printToConsole: true,
    // registerErrorHandler: true,
    // logBrowserEvents: true,
    // logUIEvents: true,
    // version: '',
    // build: '',
});

/*const origLog = Bugfender.log; 
Bugfender.log = function(str) {
    origLog.call(this, `[${config.env}] ${str}`);
}*/

const origConsoleLog = console.log;
console.log = function() {
    if (arguments[0]) arguments[0] = `[${config.env}] ${JSON.stringify(arguments[0])}`

    origConsoleLog.apply(console, arguments);
}

export { Bugfender };