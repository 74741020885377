import CustomScroll from 'react-custom-scroll';
import { Description, Title } from '@components';
import { testImg } from './testImg';

import './cardTest.scss';

/**
 * 
 * size = medium | small
 */

const CardTest = ({ title, size = "medium", short_description, price, category = "default" }) => {
    return (
        <div className={`lyfe-card-test-wrap ${size}`}>
            <div className="lyfe-card-logo">
                <img className={`test-logo ${size}`} src={testImg[category]} />
            </div>
            <div className="lyfe-card-content">
                <div className="card-title">{title}</div>
                <CustomScroll>
                    <div className="card-desc-wrap">
                        <Description style={{ marginBottom: '8px' }}>{short_description}</Description>
                        <Description style={{ marginBottom: '8px' }}>Overseen by a professional health advisor bla bla bla</Description>
                        <Description style={{ marginBottom: '8px' }}>Some another desc about bla bla bla</Description>
                    </div>
                </CustomScroll>
            </div>
        </div>
    );
}

export default CardTest;