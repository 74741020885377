import { fork, all } from 'redux-saga/effects';
import testSaga from './testSaga';
import searchSaga from './searchSaga';
import chooseSaga from './chooseSaga';
import timelineSaga from './timelineSaga';
import addressSaga from './addressSaga';

export default function* rootSaga() {
  yield all([
    fork(testSaga),
    fork(searchSaga),
    fork(chooseSaga),
    fork(timelineSaga),
    fork(addressSaga)
  ]);
}